import {Component} from '@angular/core';

import {Platform} from '@ionic/angular';
import {SplashScreen} from '@ionic-native/splash-screen/ngx';
import {StatusBar} from '@ionic-native/status-bar/ngx';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {HttpClient} from '@angular/common/http';
import {TranslateService} from '@ngx-translate/core';
import {Router} from '@angular/router';
import {GlobalService} from './services/global.service';
import {AuthenticationService} from './services/authentication.service';
import {take} from 'rxjs/operators';
import {environment} from 'src/environments/environment';
import {Pais} from './rest';

@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
    styleUrls: ['app.component.scss']
})
export class AppComponent {
    constructor(
        private platform: Platform,
        private splashScreen: SplashScreen,
        private statusBar: StatusBar,
        private translate: TranslateService,
        private router: Router,
        private global: GlobalService,
        private authenticationService: AuthenticationService,
        private http: HttpClient
    ) {
        //  Añadimos todos los lenguajes disponibles en la aplicación
        this.cargaPais();

        // inicializamos la aplicación
        this.initializeApp();
    }

    cargaPais() {
        const pais: Pais = this.global.getPais();

        if (pais) {
            const idioma = pais.idioma.codigo;
            localStorage.setItem('idioma', idioma);
            this.translate.addLangs([idioma]);

            this.translate.setDefaultLang(idioma);
            this.translate.use(idioma);

            this.translate.getTranslation(idioma).pipe(take(1)).subscribe(() => {
                this.translate.setTranslation(idioma, JSON.parse(pais.idioma.textoEstatico), true);
            });
        } else {
            this.translate.addLangs(['es', 'en']);

            const langDef = 'es';
            localStorage.setItem('idioma', langDef);
            this.translate.setDefaultLang(langDef);
            this.translate.use(langDef);

            this.translate.getTranslation(langDef).pipe(take(1)).subscribe(() => {
                // tslint:disable: max-line-length
                this.translate.setTranslation(langDef,
                    {
                        GLOBAL: {
                            ACEPTO: 'Acepto la ',
                            PROTECCION: 'protección de datos y la política de privacidad.',
                            ACEPTAR: 'Aceptar',
                            CANCELAR: 'Cancelar'
                        },
                        SERVIDOR: {
                            401: 'No autorizado',
                            404: 'Usuario no encontrado',
                            '401_LOGIN': 'Los datos introducidos no son correctos.',
                            '404_LOGIN': 'No existe ningun usuario con ese email.',
                            FALTAN_DATOS: 'Por favor, rellena todos los datos.',
                            PASS_UPDATED: 'Contraseña actualizada correctamente.',
                            PASS_NO_COINCIDE: 'La contraseña no coincide.',
                            PASS_LNG: 'La contraseña tiene que tener al menos 6 caracteres.'
                        },
                        INICIO: {
                            TITULO: 'Introduce tu email',
                            TITULO_PAIS: 'Selecciona tu pais',
                            INPUT_EMAIL: 'Email',
                            INPUT_PAIS: 'Pais',
                            EXISTE_CUENTA: '¿Ya tienes cuenta? Haz login',
                            BTN_EXISTE: 'aquí',
                            BTN_LOGIN: 'Aceptar'
                        },
                        LOGIN: {
                            TITULO: 'Inicia sesión',
                            RECUPERATITULO: 'Recuperar contraseña',
                            OLVIDADA: '¿Has olvidado la contraseña?',
                            RECUPERA: 'Introduce tu dirección de correo electrónico para proceder con la recuperación de la contraseña.',
                            CODIGO_RE: '¿No has recibido ningún código? Comprueba de nuevo la bandeja de entrada o',
                            CODIGO_PULSA1: 'pulsa aquí',
                            CODIGO_PULSA2: 'para volver a enviar.',
                            CODIGO_NEW_PASS: 'Introduce una nueva contraseña.',
                            BTN_LOGIN: 'LOGIN',
                            BTN_LOGIN_RECUPERA: 'SIGUIENTE',
                            BTN_REGISTRO: 'REGÍSTRATE',
                            BTN_UPDATE_PASS: 'ACTUALIZA CONTRASEÑA'
                        },
                        PRE_ACCESS: {
                            TITULO: 'Acceso Rubix',
                            INTRO: 'Si quieres tener acceso a contenidos exclusivos',
                            BTN_REGISTRO_1: '¡REGÍSTRATE AHORA!',
                            BTN_REGISTRO_2: 'REGÍSTRATE',
                            BTN_VISITANTE: 'SEGUIR COMO VISITANTE'
                        },
                        REGISTRO: {
                            TITULO: 'Registro',
                            OBLIGATORIOS: 'Todos los campos marcados con * son obligatorios.',
                            INPUT_EMAIL: 'Email*',
                            INPUT_NOMBRE: 'Nombre*',
                            INPUT_APELLIDOS: 'Apellidos*',
                            INPUT_PAIS: 'Pais*',
                            INPUT_PROVINCIA: 'Provincia*',
                            INPUT_EMPRESA: 'Nombre de la empresa*',
                            INPUT_PUESTO: 'Puesto*',
                            INPUT_DEPARTAMENTO: 'Departamento*',
                            INPUT_OPERARIOS: 'Nº de operarios*',
                            INPUT_COMERCIAL: 'Recomendado por...',
                            INPUT_PASS: 'Contraseña*',
                            INPUT_PASS_CONFIRM: 'Confirmar Contraseña*',
                            SEGUIR: 'SEGUIR COMO VISITANTE',
                            VOLVER: 'Volver al Formulario',
                            BTN_CAMBIAR_EMAIL: 'CAMBIAR EMAIL',
                            BTN_REGISTRO: 'REGÍSTRATE',
                            CORREO: 'info@rubix-group.com'
                        },
                        PERFIL: {
                            TITULO: 'Mis Datos',
                            TITULO_2: 'Usuario Rubix',
                            SEGMENT_1: 'Mis datos',
                            SEGMENT_2: 'Configurar avisos',
                            NOTIFICACIONES: 'Mostrar notificaciones',
                            SONIDO: 'Activar sonido para notificación',
                            EXCLUSIVO: ' Si quieres tener acceso a contenidos exclusivos',
                            NOVEDADES: '¿Quieres recibir todas las novedades y avisos de última hora en tu teléfono? Descarga nuestra aplicación de Seguridad Rubix y llévala contigo a todas partes.',
                            INPUT_EMAIL: 'Email',
                            INPUT_NOMBRE: 'Nombre',
                            INPUT_APELLIDOS: 'Apellidos',
                            INPUT_PAIS: 'Pais',
                            INPUT_PROVINCIA: 'Provincia',
                            INPUT_EMPRESA: 'Nombre de la empresa',
                            INPUT_PUESTO: 'Puesto',
                            INPUT_DEPARTAMENTO: 'Departamento',
                            INPUT_OPERARIOS: 'Nº de operarios',
                            INPUT_COMERCIAL: 'Recomendado por...',
                            INPUT_PASS_OLD: 'Contraseña antigua',
                            INPUT_PASS: 'Contraseña',
                            INPUT_PASS_CONFIRM: 'Confirmar Contraseña',
                            BTN_GUARDAR: 'GUARDAR DATOS',
                            BTN_ACTUALIZAR: 'ACTUALIZAR CONTRASEÑA',
                            BTN_LOGOUT: 'Cerrar sesión',
                            BTN_REGISTRO_1: '¡REGÍSTRATE AHORA!',
                            BTN_REGISTRO_2: 'REGÍSTRATE'
                        },
                        NOTICIAS: {
                            TITULO: 'Piensa en Seguridad'
                        },
                        LISTADO: {
                            LOADING: 'Cargando listado...',
                            BTN_NORMATIVA: 'Consultar Normativa',
                            BTN_SERVICIOS: 'Consultar Servicios',
                            FILTROS: 'Filtros',
                            BTN_MUESTRA: 'Muestra resultados',
                            RESULTADOS: 'Resultados de búsqueda del término'
                        },
                        NORMATIVA: {
                            TITULO: 'NORMATIVA'
                        },
                        FICHA: {
                            PVP: 'PVP',
                            DESCRIPCION: 'Descripción',
                            REFERENCIAS: 'Referencias',
                            CARACTERISTICAS: 'Características',
                            NORMATIVA: 'Normativa',
                            COLOR: 'Color Base',
                            SOLICITARINFO: 'A consultar'
                        },
                        CONTACTO: {
                            TITULO: 'Contacta con nosotros',
                            COMENTARIO: 'Escribe tu comentario',
                            ENVIAR: 'ENVIAR',
                            ENVIADO_TIT: 'Mensaje enviado con éxito',
                            ENVIADO_DESC: 'Hemos recibido tu mensaje, contestaremos a la mayor brevedad posible',
                            ERROR: 'Ocurrio un error'
                        },
                        FILTROS: {
                            MOSTRAR: 'Mostrar todos',
                            SALTAR: 'Saltar pregunta',
                            SIGUIENTE: 'Siguiente'
                        },
                        MENU: {

                            CONTACTAR: 'Contactar',
                            CONTACTO: 'Contacto',
                            NORMATIVA: 'Normativa',
                            BUZON: 'Mi Buzón',
                            DATOS: 'Mis Datos',
                            SERVICIOS: 'Servicios',
                            EPI: 'Menú EPI',
                            EPI_WEB: 'Selecciona el EPI adecuado para cubrir tus riesgos'
                        },
                        SERVICIOS: {
                            TITULO: 'Selecciona el SERVICIO adecuado para cubrir tus riesgos',
                            CONSULTA: 'Consulta nuestro amplio menú de servicios'
                        },
                        FOOTER: {
                            HEADER: 'Compartir',
                            COMPARTIR_OK: 'Se ha compartido con éxito. Los documentos de este artículo te llegaran a tu email de contacto.',
                            COMPARTIR_ERR: 'Ocurrio un problema al compartir'
                        },
                        FOOTER_WEB: {
                            COL1_TIT: 'Rubix Iberia',
                            COL1_DESC: 'Queremos ser el Partner de referencia en Seguridad de nuestros clientes y ayudar a implementar mejoras en el área de la Prevención de Riesgos. Nuestro objetivo es que todos los trabajadores vuelvan sanos y salvos a sus casas cada día.',
                            COL2_TIT: 'Servicios',
                            COL2_DESC: 'Colaboramos con nuestros clientes en la gestión integral de la Seguridad Laboral con un amplio menú de servicios en constante evolución y desarrollo.',
                            COL3_TIT: 'Mi perfil',
                            COL3_DESC: 'Los usuarios invitados pueden realizar consultas básicas en el buscador de seguridad más completo del mercado. Los usuarios premium cuentan con acceso a todas las funcionalidades y ventajas del buscador.',
                            COL4_TIT: 'Localiza tu contacto',
                            COL4_SUBTIT: 'Contacto',
                            COL4_DESC: 'Para cualquier duda o consulta acerca de nuestros productos / servicios de Seguridad puede contactar con nosotros a través de:',
                            COL4_ZONA1: {
                                TIT: 'Zona Norte',
                                DESC: 'Gonzalo Amiano',
                                EMAIL: 'gonzalo.amiano@rubix.com',
                                TEL: '+34 676 035 719'
                            },
                            COL4_ZONA2: {
                                TIT: 'Zona Centro Sur',
                                DESC: 'Diego Solana',
                                EMAIL: 'diego.solana@rubix.com',
                                TEL: '+34 606 756 737'
                            },
                            COL4_ZONA3: {
                                TIT: 'Zona Este',
                                DESC: 'Emilio García',
                                EMAIL: 'emilio.garcia@rubix.com',
                                TEL: '+34 639 101 238'
                            },
                            COL4_ZONA4: {
                                TIT: 'Zona Oeste',
                                DESC: 'Juan Carlos Boo',
                                EMAIL: 'juancarlos.boo@rubix.com',
                                TEL: '+34 609 42 44 32'
                            },
                            TEL: '902190500',
                            EMAIL: 'seguridad@rubix.com',
                            AVISO: 'Aviso legal',
                            COOKIES: 'Cookies'
                        },
                        CABECERA: {
                            BUSCAR: 'Buscar',
                            INVENT: 'Servicios de Gestión de Inventario',
                            LOGIN: 'Login / Registrarse',
                            PERFIL: 'Mi perfil'
                        },
                        INVITADO: {
                            ALERT_REG_TITULO: 'Contenido premium',
                            ALERT_REG_TEXT: 'Aún navegas como visitante, regístrate para acceder a contenidos exclusivos.',
                            ALERT_REG_BTN1: 'SEGUIR COMO VISITANTE',
                            ALERT_REG_BTN2: 'REGÍSTRATE',
                            FICHA_TXT1: 'Aún navegas como visitante, en el momento que realices tu registro contarás con accesos a fichas técnicas y contenidos exclusivos. Regístrate ',
                            FICHA_TXT2: 'aquí'
                        }
                    }
                    , true);
            });
        }
    }

    initializeApp() {
        this.platform.ready().then(() => {
            this.statusBar.styleDefault();
            this.splashScreen.hide();

            if (!this.global.getPais()) { // Le mandamos a inicio
                this.router.navigate([this.global.getPlt() + '/']);
            } else {
                // Primero comprobamos si ya tenía la sesión iniciada
                this.authenticationService.getToken().subscribe((token) => {
                    if (token) {
                        this.authenticationService.currentUser.subscribe(usuario => {
                            if (usuario.tipoUsuario === 0) {
                                this.authenticationService.email = usuario.email;
                                this.router.navigate([this.global.getPlt() + '/pre-access']);
                            } else {
                                if (this.global.getPlt() === '/web') {
                                    this.router.navigate([this.global.getPlt() + '/epis']);
                                } else {
                                    this.router.navigate([this.global.getPlt() + '/private']);
                                }
                            }
                        });
                    } else {
                        // Le llevamos a la página de inicio
                        this.router.navigateByUrl(this.global.getPlt());
                    }
                });
            }
        });
    }
}
